import React from 'react'
import ReactPlayer from "react-player";
function Clientspeech() {
    return (
        <div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                    <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/shorts/yx0vvQIu4es"/>
                                <div className="card-body">
                                    <div className='coloradd'>
                                    <h5 className="card-text mt-2 mb-2"> <b>Our Respected Client Review 
                                    </b></h5>
                                    </div>
                                 


                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                     
                        <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/watch?v=m4MscnU5CX0"/>
                                <div className="card-body">
                                    <div className='coloradd'>
                                    <h5 className="card-text mt-2 mb-2"> <b>Our Respected Client Review</b></h5>
                                    </div>
                                    

                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Clientspeech