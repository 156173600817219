import React from 'react'
import ReactPlayer from "react-player";
function Videopage() {
    return (
        <div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                    <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/watch?v=Fujz8aN7Jbc"/>
                                <div className="card-body">
                                    <div className='coloradd'>
                                    <h5 className="card-text mt-2 mb-2"> <b>Automatic Water Pump Controller Machine Installation For Contact :  <a href='tel: 7384707032'>7384707032</a> 
                                    </b></h5>
                                    </div>
                                 


                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                     
                        <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2" width="100%" height="100%" url="https://www.youtube.com/watch?v=lPvIM1RD0KE"/>
                                <div className="card-body">
                                    <div className='coloradd'>
                                    <h5 className="card-text mt-2 mb-2"> <b>Demo Video Automatic Water Pump Controller, Installation For Contract : <a href='tel: 7384707032'>7384707032</a> </b></h5>
                                    </div>
                                    

                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3">
                            <div className=" mb-4">
                                <ReactPlayer className="mb-2 nio" width="100%" height="100%" url="https://www.youtube.com/watch?v=7BdF02SJHZg" />
                                <div className="card-body">
                                    <div className='coloradd'>
                                    <h5 className="card-text mt-2 mb-2"> <b>Automatic Water Pump Controller Machine Install Bajrangi Gupta Da ( Install For  <a href='tel: 7384707032'>7384707032</a>  )</b></h5>
                                    </div>
                                    

                                    <div className="d-flex justify-content-between align-items-center">



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Videopage