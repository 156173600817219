

import React from "react";
import Otherb from "../pages/Otherb";

function Otherbpage (){
   return(
        <>
        <Otherb/>
        
        </>
   );
}

export default Otherbpage