

import React from "react";
import Sliders from "../images/banner02.jpg";
import Slidersg from "../images/apcservices.jpg";
import autometicpump from "../images/sgdirector.jpg";
import Homeinterior from "../images/homedesign.jpg";

function Slider (){
    return(
            <>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>


  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={autometicpump} className="d-block w-100" alt="autometicpump_controller"/>
    </div>
    <div className="carousel-item">
      <img src={Slidersg} className="d-block w-100" alt="sgsolution"/>
    </div>
    <div className="carousel-item">
      <img src={Sliders} className="d-block w-100" alt="solutionfornextgeneration"/>
    </div>
    <div className="carousel-item">
      <img src={Homeinterior} className="d-block w-100" alt="solutionfornextgeneration"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
            
            </>
    );
}

export default Slider;